import React, { useContext } from 'react'
import { DrawerContext } from 'components/Drawer'
import styled from 'styled-components'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import LogoSmall from './logosmall'
import Arrow from './arrow'

const H1 = styled.h1`
  font-size: 18px;
  font-weight: bold;
  line-height: 25px;
  margin-bottom: 4px;
`

const Subline = styled.span`
  font-size: 13px;
  line-height: 20px;
  font-weight: normal;
  opacity: 0.63;
`

const HeaderTop = styled.div`
  color: white;
  height: 5.375rem;
  width: calc(100% - 32px);
  padding: 1rem 16px;
  position: fixed;
  /* top: env(safe-area-inset-top); */
  top: calc(env(safe-area-inset-top) + 1.5rem);
  z-index: 2;
`

const Navbar = styled.div`
  display: flex;
  justify-content: space-between;
`

const AppLogo = styled.div`
  margin: 1rem;
  flex-grow: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Drawer = styled.img`
  margin: -1rem;
  padding: 1rem;
  height: 18px;
  width: 18px;
  flex-grow: 0;
  display: flex;
  align-items: center;
`

const WithoutArrow = styled.div``

function Header({ title, subtitle, navigation = 'burger', onBack }) {
  const { open, setOpen } = useContext(DrawerContext)
  return (
    <>
      <HeaderTop>
        <Navbar>
          {navigation === 'burger' ? (
            <Drawer src="/images/burger.svg" onClick={() => setOpen(true)} />
          ) : (
            (navigation = 'back' ? (
              <ChevronLeft style={{ fontSize: '32px' }} onClick={onBack} />
            ) : (
              <WithoutArrow />
            ))
          )}
          <LogoSmall height="32" width="32" />
        </Navbar>
        <H1>{title}</H1>
        <Subline>{subtitle}</Subline>
      </HeaderTop>
    </>
  )
}

export default Header
