import styled from 'styled-components'

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 16px; 
  padding-right: 16px;
  /* margin-top: ${({ header }) => (header ? 8 : 0)}rem; */
  margin-top: ${({ header }) => (header ? 9.5 : 0)}rem;
  margin-bottom: ${({ footer }) => (footer ? 4.875 : 1)}rem;
  overflow-y: scroll;
  overflow-x: hidden;
  /* height: calc(
    100% - ${({ header, footer }) => !!footer * 3.875 + !!header * 8 + 1}rem
  ); */
  height: calc(
    100% - ${({ header, footer }) => !!footer * 5.375 + !!header * 8 + 4.525}rem
  );
  padding-bottom: 1rem;
  .keyboard & {
    height: calc(100% - ${({ header }) => !!header * 9}rem);
  }
`

export default ContentWrapper
