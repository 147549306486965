import React from 'react'
import ArrowImage from '../images/arrow.png'

function Arrow({ height, width }) {
  return (
    <img src={ArrowImage} alt="back" height={{ height }} width={{ width }} />
  )
}

export default Arrow
